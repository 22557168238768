export function fixHref(val?: string) {
  // Return the input value immediately if it's null or undefined
  if (val === null || val === undefined) {
    return val;
  }

  // Trim any leading or trailing whitespace from the input value
  let href = val.trim();

  // Only process the string to remove domain information if it does NOT start with 'https://artists.spotify.com/c'
  // This preserves links that specifically start with this path, leaving them untouched.
  if (!href.startsWith('https://artists.spotify.com/c')) {
    // Remove the 'artists.spotify.com' domain, including optional prefixes like https://, http://, or //
    // For example:
    // - 'https://artists.spotify.com/some/path' becomes '/some/path'
    // - 'http://artists.spotify.com' becomes '/'
    href = href.replace(/^((https|http):)?(\/\/)?artists\.spotify\.com/, '');
  }

  // If the resulting href is an empty string (e.g., the input was just the domain with no path),
  // assign it the value of '/' to ensure it refers to the root URL
  if (href === '') href = '/';

  // Return the final processed href
  return href;
}
