import React from 'react';
import Link from 'next/link';
import { useTracking } from '../../features/Tracking';
import { fixHref } from './utils';
import { InteractiveElementType } from '@spotify-internal/frodor-modules';

// customComponent element MUST support a component prop
// to allow for passthrough of the Link
// encore interactive elements already do
export const InteractiveElement: InteractiveElementType = ({
  customComponent,
  href,
  children,
  trackingCategory,
  trackingLabel,
  onClick,
  ...rest
}) => {
  const { sendEvent } = useTracking();
  const onInteraciveElementClick = () => {
    sendEvent({
      eventCategory: trackingCategory,
      eventAction: 'click',
      eventLabel: trackingLabel,
    });
    onClick && onClick();
  };

  // dashboard links should not use nextlink
  if (
    href &&
    (href.startsWith('https://artists.spotify.com/c/') ||
      href === 'https://artists.spotify.com/c')
  ) {
    if (customComponent) {
      const Component = customComponent;
      return (
        <Component
          href={fixHref(href)}
          {...rest}
          onClick={onInteraciveElementClick}
        >
          {children}
        </Component>
      );
    }
    return (
      <a href={fixHref(href)} {...rest} onClick={onInteraciveElementClick}>
        {children}
      </a>
    );
  }
  // non dashboard links can use nextlink
  if (customComponent) {
    const Component = customComponent;
    return (
      <Component
        component={href && Link}
        href={fixHref(href)}
        {...rest}
        onClick={onInteraciveElementClick}
      >
        {children}
      </Component>
    );
  } else if (href) {
    return (
      <Link href={fixHref(href)!} {...rest} onClick={onInteraciveElementClick}>
        {children}
      </Link>
    );
  }
  return (
    <button {...rest} onClick={onInteraciveElementClick}>
      {children}
    </button>
  );
};
