// Define a type for the dimensions parameter
type Dimensions = string[];

// Define a type for the opts parameter
interface EventOptions {
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

// dimensions in format of string[]
export const setInitialDimensions = (dimensions: Dimensions): void => {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    // @ts-ignore
    const dataLayer = (window.dataLayer || []) as any[];
    dataLayer.push({
      event: 'initial_datalayer_push',
      dimensions,
    });
  }
};

// opts in the format of category, label, and action
export const sendEvent = (opts: EventOptions): void => {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    // @ts-ignore
    const dataLayer = (window.dataLayer || []) as any[];
    dataLayer.push({ event: 'my_custom_event', opts });
  }
};
