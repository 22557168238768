import storage from 'local-storage-fallback';
import { sendEvent as gaSendEvent } from './GoogleAnalytics';

import { eventSender, GabitoEvent } from './gabito';
import { createFrodorPageEventNonAuth } from '@spotify-internal/event-definitions/es5/events/createFrodorPageEventNonAuth';
import { createFrodorPageEvent } from '@spotify-internal/event-definitions/es5/events/createFrodorPageEvent';
import { UserType } from '../../utils/CurrentUserProvider';

/**
 * Represents the options for sending an event.
 */
export interface EventOptions {
  /** The category of the event. */
  eventCategory: string;
  /** The action associated with the event. */
  eventAction: string;
  /** The label for the event. */
  eventLabel: string;
  /** The user associated with the event, if available. */
  user?: UserType | null;
  /** The pathname for the event, used when not in a browser context. */
  pathname?: string;
}

/**
 * Sends an event to both Google Analytics and Gabito.
 * Determines if the user is authenticated and sends appropriate events.
 *
 * @param eventCategory - The category of the event.
 * @param eventAction - The action that triggered the event.
 * @param eventLabel - A label for the event.
 * @param user - The current user, if authenticated.
 * @param pathname - The pathname to use when not in a browser context.
 */
export function sendEvent({
  eventCategory,
  eventAction,
  eventLabel,
  user,
  pathname,
}: EventOptions) {
  // Prepare Google Analytics event
  const gaEvent = { eventCategory, eventAction, eventLabel };
  debug('sending GA event', gaEvent);
  gaSendEvent(gaEvent);

  const artistId = user?.username
    ? storage.getItem(`${user.username}:analytics:lastVisitedArtist`)
    : null;
  const gabitoEvent: GabitoEvent = user
    ? createFrodorPageEvent({
        url:
          typeof window !== 'undefined' ? window.location.href : pathname || '',
        pathname:
          typeof window !== 'undefined'
            ? window.location.pathname
            : pathname || '',
        title: document.title,
        partner_id: user.partnerIds?.google,
        is_employee: null,
        has_access: user.hasAccess,
        artist_id: artistId,
        category: eventCategory,
        action: eventAction,
        label: eventLabel,
      })
    : createFrodorPageEventNonAuth({
        url:
          typeof window !== 'undefined' ? window.location.href : pathname || '',
        pathname:
          typeof window !== 'undefined'
            ? window.location.pathname
            : pathname || '',
        title: document.title,
        category: eventCategory,
        action: eventAction,
        label: eventLabel,
      });

  debug(
    user ? 'sending gabito auth event' : 'sending gabito non-auth event',
    gabitoEvent,
  );
  eventSender.send(gabitoEvent);
}

/**
 * Logs debug information to the console if debugging is enabled.
 *
 * @param args - The arguments to log.
 */
function debug(...args: any[]) {
  if (
    typeof window !== 'undefined' &&
    (window.location.search || '').includes('debug_tracking')
  ) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}
