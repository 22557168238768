// ignore-string-externalization

import React from 'react';
import storage from 'local-storage-fallback';
import { useRouter } from 'next/router';
import { setInitialDimensions } from './GoogleAnalytics';
import { createFrodorPageView } from '@spotify-internal/event-definitions/es5/events/createFrodorPageView';
import { createFrodorPageViewNonAuth } from '@spotify-internal/event-definitions/es5/events/createFrodorPageViewNonAuth';
import { eventSender, GabitoEvent } from './gabito';
import { useCurrentUser } from '../../utils/CurrentUserProvider';

/**
 * The Track component is responsible for tracking page views
 * and sending appropriate events based on the user's authentication status.
 */
export const Track: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const lastPathname = React.useRef<string | null>(null);
  const { asPath: pathname } = router;
  const user = useCurrentUser();

  React.useEffect(() => {
    // Return early if the user has not been resolved yet
    if (user === undefined) return;

    // Determine the current URL and pathname
    const href =
      typeof window !== 'undefined' ? window.location.href : pathname;

    // Retrieve the last visited artist ID from local storage if available
    const artistId = user?.username
      ? storage.getItem(`${user.username}:analytics:lastVisitedArtist`)
      : null;

    // Get the Google partner ID from the user data
    const partnerId = user?.partnerIds?.google;

    // Set initial dimensions for Google Analytics based on user data
    setInitialDimensions({
      userId: partnerId,
      dimension1: 'external',
      dimension2: 'isNotFive',
      dimension3: user?.hasAccess ? 'Yes' : 'No',
      ...(artistId && { dimension4: artistId }),
    });

    // Create a Gabito event based on whether the user is authenticated
    const gabitoEvent: GabitoEvent = user
      ? createFrodorPageView({
          url: href,
          pathname,
          title: document.title,
          partner_id: partnerId,
          is_employee: null,
          has_access: user.hasAccess,
          artist_id: artistId,
        })
      : createFrodorPageViewNonAuth({
          url: href,
          pathname,
          title: document.title,
        });

    // Log the Gabito event if the pathname has changed since the last render
    if (pathname !== lastPathname.current) {
      lastPathname.current = pathname;
      eventSender.send(gabitoEvent);
    }
  }, [pathname, user]);

  // Render the children components
  return <>{children}</>;
};
