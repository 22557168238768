import React, { createContext, useContext, useState, ReactNode } from 'react';
import { webgateFetch, WEBGATE_DOMAIN } from '../@mrkt/features/webgate-fetch';

// Define the endpoint for fetching user data
export const endpoint = `${WEBGATE_DOMAIN}/s4x-me/v0/me`;

// Define the shape of the user data
export interface UserType {
  name: string;
  username: string;
  loginEmail: string;
  imageUrl: string;
  largeImageUrl: string;
  hasAccess: boolean;
  partnerIds: {
    google: string;
    qualaroo?: string;
    getfeedback?: string;
  };
  needsToAcceptTerms?: boolean;
}

// Create a context for the current user
const CurrentUserContext = createContext<UserType | null | undefined>(null);

// Fetch user data function
export async function fetchUserData() {
  try {
    const response = await webgateFetch(endpoint);
    if (response.status === 401) {
      // Handle unauthorized access
      return null;
    }
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    throw new Error(`Failed to fetch user data:, ${error}`);
  }
}

// Define the provider component
interface CurrentUserProviderProps {
  children: ReactNode;
}

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<UserType | null | undefined>();

  React.useEffect(() => {
    // Fetch user data only once when the provider mounts
    fetchUserData()
      .then(setCurrentUser)
      .catch(() => {
        setCurrentUser(null); // Handle fetch failure gracefully
      });
  }, []); // Empty dependency array ensures this runs only once

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
};

// Custom hook to use the current user context
export const useCurrentUser = () => useContext(CurrentUserContext);
