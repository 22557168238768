// ignore-string-externalization
import {
  createClientIdContext,
  createUserAgentContext,
  createApplicationContext,
} from '@spotify-internal/event-sender/es5/contexts';
import { createEventSender, EventData } from '@spotify-internal/event-sender';
import { getSpotifyClientId, getVersion } from '../../../../features/src/env';
import { transport } from '../../../../features/src/transport';

/**
 * Constructs the context array for the event sender, including client ID,
 * application version, and optionally the user agent if in a browser environment.
 *
 * @returns An array of context objects to be used by the event sender.
 */
function getContext() {
  // Create application context with the current application version
  const applicationContext = createApplicationContext({
    version: getVersion(),
  });

  // Initialize the context array with client ID and application context
  const context = [
    createClientIdContext(getSpotifyClientId()),
    applicationContext,
  ];

  // Add user agent context if running in a browser
  if (typeof window !== 'undefined') {
    return [...context, createUserAgentContext()];
  }

  return context;
}

/**
 * Instance of the event sender configured with the transport and context.
 * Used to send events to the backend.
 *
 * @see https://backstage.spotify.net/docs/event-sender-js/
 */
export const eventSender = createEventSender({
  transport,
  context: getContext(),
});

/**
 * Extends the EventData type to define the structure of a Gabito event.
 * This includes a data field with key-value pairs where values can be of
 * various primitive types or null/undefined.
 */
export type GabitoEvent = EventData & {
  data: {
    [key: string]: string | number | boolean | string[] | null | undefined;
  };
};
