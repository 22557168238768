// ignore-string-externalization
import '@spotify-internal/encore-web/css/fonts.css';
import '@spotify-internal/encore-web/css/encore-text-styles.css';
import React from 'react';
import getCookies from 'next-cookies';
import '../polyfill';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import '../utils/reportNodeMetrics';
import '../features/ubi/reportNavigations';
import App from 'next/app';
import { AppTheme } from '../../features/src/encore-theme/creator';
import Head from 'next/head';
import withRedux from 'next-redux-wrapper';
import { store as configureStore } from '../utils/redux/store';
import { Page } from '../features/Layout';
import { isPreview } from '../features/utils';
import { isWebView } from '../../features/src/isWebView';
import { QualarooSurvey } from '../../features/src/QualarooSurvey';
import { compose } from 'redux';
import { startAxeOverlay } from '../../features/src/start-axe-overlay';
import { track } from '@spotify-internal/track-js';
import { appWithI18n } from '../../features/src/i18n/appWithI18n';
import { LanguageHead } from '../../features/src/i18n/components/LanguageHead';
import { MrktAppLanguageLogger } from '../../features/src/i18n/components/MrktAppLanguageLogger';
import { appWithCssTransform } from '../../features/src/css/appWithCssTransform';
import * as Sentry from '@sentry/react';
import { FrodorModulesProvider } from '../utils/FrodorModulesProvider';
import { MastheadProvider } from '../utils/MastheadProvider';
import { getLanguage } from '../utils/getLanguage';
import { LanguageSelectionProvider } from '../../features/src/i18n/components/LanguageSelection';
import {
  FeatureFlagsProvider,
  FeatureFlagsMenu,
} from '../../features/src/FeatureFlags';
import { PageWithRedux } from '../features/Layout/PageWithRedux';
import { CurrentUserProvider } from '../utils/CurrentUserProvider';

// Determine if the path requires Redux
function isReduxPath(path: string) {
  const segments = path.split('/');
  const root = segments[1];
  const isBlogHub = root === 'blog' && segments.length === 2;
  return ['videos'].includes(root) || isBlogHub;
}

// Define PageComponent to handle page rendering with or without Redux
const PageComponent = ({ path, props, Component, store }: any) => {
  if (isReduxPath(path)) {
    return <PageWithRedux store={store} props={props} Component={Component} />;
  }
  // @ts-ignore
  return <Page props={props} Component={Component} />;
};

// @ts-ignore
export class Site extends App {
  // Fetch initial props for the page
  static async getInitialProps({ Component, ctx }: any) {
    const cookies = getCookies(ctx);
    const preview = isPreview(ctx);
    const activeSession = 'sp_dc' in cookies;
    const smartlingLanguage = getLanguage(ctx.locale);
    const locale = ctx.locale;

    const props =
      (await Component.getInitialProps?.({
        ...ctx,
        preview,
        smartlingLanguage,
        locale,
        activeSession,
      })) || {};

    return {
      initialProps: { ...props, preview, activeSession },
    };
  }

  // Render the component
  render() {
    Sentry.init({
      dsn: 'https://b77895087f0946d4b6baf7b3e1d9b999@o22381.ingest.sentry.io/4504498620268544',
    });

    const { Component, pageProps, store, initialProps, router } = this
      .props as any;

    return (
      <>
        <LanguageHead base="https://artists.spotify.com" />
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>

        <AppTheme {...(this.props as any)}>
          <CurrentUserProvider>
            <LanguageSelectionProvider>
              <MastheadProvider
                contentfulEntryId={
                  initialProps.preview ? initialProps.id : undefined
                }
              >
                <FrodorModulesProvider>
                  <FeatureFlagsProvider>
                    <PageComponent
                      store={store}
                      path={router.pathname}
                      props={{
                        ...pageProps,
                        ...initialProps,
                      }}
                      Component={Component}
                    />
                    {!isWebView() && <QualarooSurvey />}
                    <FeatureFlagsMenu />
                  </FeatureFlagsProvider>
                </FrodorModulesProvider>
              </MastheadProvider>
            </LanguageSelectionProvider>
          </CurrentUserProvider>
        </AppTheme>
        <MrktAppLanguageLogger />
      </>
    );
  }

  // Component did mount lifecycle method
  componentDidMount() {
    startAxeOverlay();
    if (!isWebView()) {
      track({
        market: 'not_set',
        googleTagManagerId:
          process.env.NODE_ENV === 'production' ? 'GTM-NP2G964' : undefined,
        oneTrust:
          // ignore cookie banner in synthetic tests for now
          !navigator.webdriver &&
          // cookie is only set on *.spotify.com
          window.location.host.endsWith('.spotify.com'),
      });
    }
  }
}

// Export the composed component with necessary HOCs
export default compose(
  appWithCssTransform,
  appWithI18n,
  withRedux(configureStore),
)(Site);
