// ignore-string-externalization

import React from 'react';
import { usePathname } from 'next/navigation';

import { EventOptions, sendEvent } from './sendEvent';
import { useCurrentUser, UserType } from '../../utils/CurrentUserProvider';

export function useTracking() {
  const user = useCurrentUser();
  const pathname = usePathname();
  const userRef = React.useRef<UserType | null | undefined>(user);

  React.useEffect(() => {
    userRef.current = user;
  }, [user]);

  return {
    sendEvent: ({
      eventCategory,
      eventAction,
      eventLabel,
    }: {
      eventCategory: string;
      eventAction: string;
      eventLabel: string;
    }) =>
      sendEvent({
        eventCategory,
        eventAction,
        eventLabel,
        user: userRef.current,
        pathname,
      } as EventOptions),
  };
}

export { Track } from './Track';
